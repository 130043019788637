import React, { useEffect, useState } from 'react'
import { Editable, Slate } from 'slate-react'
import { Emoji } from 'emoji-mart'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Descendant, Editor } from 'slate'
import useSWR from 'swr'
import { httpClient } from '../../../shared/api/http-client'
import FormInput from '../../../shared/components/form-input'
import Modal from '../../../shared/components/modal'
import PrimaryButton from '../../../shared/components/primary-button'
import { BadRequest } from '../../../shared/errors/bad-request'
import { InternalError } from '../../../shared/errors/internal-error'
import EmojiIcon from '../../../shared/icons/emoji-icon'
import ImageIcon from '../../../shared/icons/image-icon'
import PlusIcon from '../../../shared/icons/plus-icon'
import ImageUploader from 'shared/components/image-uploader'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import { MentionSuggestions } from 'modules/community/components/editor/elements/suggestion-mention/suggestion-mention'
import { useEditor } from 'modules/community/components/editor/hooks/use-editor'
import { insertMention } from 'modules/community/components/editor/utils/mention'
import { generatePathFromName } from 'modules/community/hooks/path-utils'
import {
  deserializeHTML,
  insertImage,
  serializeHTML,
} from 'modules/community/hooks/slate-editor-utils'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import { PostInterface } from 'modules/community/types/post-interface'
import HoveringToolbar from 'modules/post/horizontal-toolbar'
import { usePost } from '../hooks/use-post'

type UpdatePostProps = {
  post: PostInterface
  onCloseModal: () => void
  onUpdateSuccess: () => void
}

const withImages = (editor: Editor) => {
  const { isVoid } = editor

  editor.isVoid = element => (element.type === 'image' ? true : isVoid(element))

  return editor
}

function UpdatePost({ post, onCloseModal, onUpdateSuccess }: UpdatePostProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const [opened, setOpened] = useState(true)
  const [path, setPath] = useState(post.path)
  const [title, setTitle] = useState(post.title)
  const [topic, setTopic] = useState<number | null>(post.topic.id)
  const contentObject = new DOMParser().parseFromString(post.content, 'text/html')
  const { communityPath } = router.query
  const { data: community } = useCommunityWithTopics()
  const [pathError, setPathError] = useState('')
  const [topicError, setTopicError] = useState('')
  const [titleError, setTitleError] = useState('')
  const [commonError, setCommonError] = useState('')
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)
  const [isButtonActive, setIsButtonActive] = useState(false)
  const { mutate: mutatePosts, data: posts = [] } = useSWR<PostInterface[]>('posts')

  const { mutate: mutatePost } = usePost({
    communityPath: community ? community.path : (communityPath as string),
    topicPath: post.topic.path,
    postPath: post.path,
  })

  const {
    editor,
    content,
    serializedTextHtml,
    target,
    suggestions,
    suggestionsLoading,
    setTarget,
    contentError,
    setContentError,
    insertEmoji,
    updateEditorContent,
    renderElement,
    renderLeaf,
  } = useEditor({
    initialContent: deserializeHTML(contentObject.body, post.mentions) as Descendant[],
    path,
  })

  const EmojiPicker = dynamic(() => import('../../community/components/emoji-picker'))

  function convertNameToPath(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      setPath(generatePathFromName(title))
    }
  }

  const handleSendForm = async () => {
    try {
      const { data } = await httpClient.patch<PostInterface>(`/api/community/post/${post.id}`, {
        title,
        path,
        content: serializedTextHtml,
        topic: topic,
      })

      await mutatePosts(prev => {
        if (prev?.length) {
          return prev.map(p => (p.id === data.id ? data : p))
        }
        return posts
      }, true)
      await mutatePost(data)
      onUpdateSuccess()
      setOpened(false)
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors?.fields?.title) {
          setTitleError(e.errors?.fields?.title.join('\n'))
        }
        if (e.errors?.fields?.path) {
          setPathError(e.errors?.fields?.path.join('\n'))
        }
        if (e.errors?.fields?.topic) {
          setTopicError(e.errors?.fields?.topic.join('\n'))
        }
        if (e.errors?.fields?.content) {
          setContentError(e.errors?.fields?.content.join('\n'))
        } else {
          setCommonError(e.errors.common.join('\n'))
        }
      } else if (e instanceof InternalError) {
        setCommonError(t('core.error.internal_error_message'))
      }
    }
  }

  const handleAddEmoji = (emoji: Emoji) => {
    setEmojiPickerVisible(false)
    // @ts-ignore
    insertEmoji(emoji.native as string)
  }

  function closeModal() {
    setOpened(false)
    onCloseModal()
  }

  function updatePostTitle(e: React.ChangeEvent<HTMLInputElement>) {
    setTitleError('')
    setTitle(e.target.value)
  }

  function updatePath(e: React.ChangeEvent<HTMLInputElement>) {
    setPathError('')
    setPath(e.target.value)
  }

  function updateTopic(e: React.ChangeEvent<HTMLSelectElement>) {
    setTopicError('')
    setTopic(Number(e.target.value))
  }

  useEffect(() => {
    const textHtml = serializeHTML(editor)
    if (
      title !== post.title ||
      path !== post.path ||
      textHtml !== post.content ||
      topic !== post.topic.id
    ) {
      setIsButtonActive(true)
    } else {
      setIsButtonActive(false)
    }
  }, [title, path, content, topic])

  return (
    <>
      <Modal opened={opened} onClose={closeModal} title={t('settings.update_post.modal.title')}>
        <div className="mb-6">
          <label className="relative font-medium">
            <FormInput
              maxLength={256}
              value={title}
              onChange={updatePostTitle}
              label={t('home.add_post.title')}
            />
            <span className="absolute right-1 top-9 text-darkblue/50">{title.length} / 256</span>
          </label>
          {titleError && <p className="mt-2 text-sm text-red">{titleError}</p>}
        </div>
        <div className="mb-6">
          <label className="relative font-medium">
            <FormInput
              maxLength={32}
              value={path}
              onChange={updatePath}
              onFocus={convertNameToPath}
              label={t('home.add_post.path')}
            />
            <span className="absolute right-1 top-9 text-darkblue/50">{path.length} / 32</span>
          </label>
          {pathError && <p className="mt-2 text-sm text-red">{pathError}</p>}
        </div>
        <div className="mb-6">
          <label className="relative font-medium">
            <span>{t('home.add_post.topic')}</span>
            <select
              className="mt-1 w-full rounded-lg border border-gray px-4 py-2.5 focus:border-gray focus:outline-none"
              onChange={updateTopic}
              value={Number(topic)}
            >
              <option key="choose-topic">{t('home.add_post.choose_topic')}</option>
              {community?.topics.map(topic => (
                <option key={topic.id} value={topic.id}>
                  {topic.name}
                </option>
              ))}
            </select>
          </label>
          {topicError && <p className="mt-2 text-sm text-red">{topicError}</p>}
        </div>
        <div className="mb-6">
          <label className="color-text-black font-medium">
            <span>{t('home.add_post.content')}</span>
            <Slate editor={editor} initialValue={content} onChange={updateEditorContent}>
              <HoveringToolbar />
              <Editable
                style={{
                  border: 'solid',
                  borderColor: '#dce0e9',
                  borderRadius: '5px',
                  borderWidth: '1px',
                  maxHeight: '300px',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  padding: '15px',
                  minHeight: '200px',
                  backgroundColor: 'rgb(249, 251, 252)',
                }}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
              />
              {target && (suggestionsLoading || (suggestions && suggestions.length > 0)) && (
                <MentionSuggestions
                  target={target}
                  editor={editor}
                  items={suggestions || []}
                  isLoading={suggestionsLoading}
                  onSelect={char => {
                    if (target) {
                      insertMention(
                        editor,
                        {
                          displayName: char.displayName,
                          id: char.userId,
                          profileImageUrl: char.profileImageUrl,
                        },
                        target,
                      )
                      setTarget(null)
                    }
                  }}
                />
              )}
            </Slate>
          </label>
          {contentError && <p className="mt-2 text-sm text-red">{contentError}</p>}
        </div>
        <div className="mb-6 flex items-center">
          <EmojiIcon
            className="mr-2 cursor-pointer fill-bluegray"
            onClick={() => setEmojiPickerVisible(prev => !prev)}
          />
          {emojiPickerVisible && (
            <EmojiPicker
              setEmojiPickerVisible={setEmojiPickerVisible}
              onEmojiSelect={handleAddEmoji}
            />
          )}
          <ImageUploader
            imageCropperProps={{
              // NOTE: This needs for a crop box has a free ratio - https://github.com/fengyuanchen/cropperjs?tab=readme-ov-file#aspectratio
              aspectRatio: NaN,
            }}
            fileSelectorDropZoneClassName="p-0 h-auto w-min"
            icon={<ImageIcon className="cursor-pointer fill-bluegray" />}
            acceptTypes={fileInputAcceptTypes.jpg_jpeg_png}
            onUploadSuccess={dataFile => {
              insertImage(editor, dataFile.path)
            }}
          />
          <PrimaryButton
            className={`ml-auto ${
              isButtonActive ? 'bg-darkblue' : 'pointer-events-none bg-bluegray'
            }`}
            onClick={handleSendForm}
          >
            <PlusIcon />
            {t('settings.update_post.modal.title')}
          </PrimaryButton>
        </div>
        <div>{commonError && <p className="mt-2 text-sm text-red">{commonError}</p>}</div>
      </Modal>
    </>
  )
}

export default UpdatePost
