import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'next-i18next'
import Confirmation from 'shared/components/confirmation-modal'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ThreeDotsNavIcon from 'shared/icons/three-dots-nav-icon'
import { handleWithoutPropagating } from 'shared/utils/handle-without-propagating'
import UpdatePost from 'modules/post/components/update-post'
import { PostInterface } from '../types/post-interface'
import { ConfirmationRemovePostBanUser } from './confirmation-remove-post-ban-user'

type PostModalEditProps = {
  post: PostInterface
  deletePost: () => void
  deletePostAndBanUser: () => void
}

const PostModalEdit = ({ post, deletePost, deletePostAndBanUser }: PostModalEditProps) => {
  const { isOwner, user } = useUser()
  const [isUpdateModalOpened, setIsUpdateModalOpened] = useState(false)
  const [isRemovePostOpened, setIsRemovePostOpened] = useState(false)
  const [isRemovePostAndBanUserOpened, setIsRemovePostAndBanUserOpened] = useState(false)
  const isPostDeletable = isOwner || !post.author?.workspaceRole
  const isPostAuthor = user && isAuthorizedUser(user) && post.userId === user.id

  const { t } = useTranslation()

  function resetRemovablePost() {
    setIsRemovePostOpened(false)
  }

  function resetUpdatePost() {
    setIsUpdateModalOpened(false)
  }

  const resetRemovePostAndBanUser = () => {
    setIsRemovePostAndBanUserOpened(false)
  }

  async function removePost() {
    await deletePost()
    setIsRemovePostOpened(false)
  }

  const removePostAndBanUser = async () => {
    await deletePostAndBanUser()
    setIsRemovePostAndBanUserOpened(false)
  }

  return (
    <>
      <Menu
        onClick={handleWithoutPropagating()}
        as="div"
        className="relative ml-auto flex items-center"
      >
        <MenuButton onClick={handleWithoutPropagating()}>
          <ThreeDotsNavIcon className="text-darkblue/80 hover:text-darkblue" />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            as="ul"
            className="absolute right-0 top-0 z-10 w-max min-w-[144px] max-w-sm origin-center rounded-md bg-lightblue shadow-lg"
          >
            {isPostDeletable && (
              <MenuItem as="li" className="px-1 py-1">
                {({ focus }) => (
                  <button
                    className={`${
                      focus ? 'text-darkblue' : 'text-darkblue/40'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => setIsRemovePostOpened(true)}
                  >
                    {t('settings.form.delete.label')}
                  </button>
                )}
              </MenuItem>
            )}
            {/* TODO: enable when the bans are full done */}
            {false && (
              <MenuItem as="li" className="px-1 py-1">
                {({ focus }) => (
                  <button
                    className={`${
                      focus ? 'text-darkblue' : 'text-darkblue/40'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => setIsRemovePostAndBanUserOpened(true)}
                  >
                    {t('settings.form.delete_and_ban_user.label')}
                  </button>
                )}
              </MenuItem>
            )}
            {isPostAuthor && (
              <MenuItem as="li" className="px-1 py-1">
                {({ focus }) => (
                  <button
                    className={`${
                      focus ? 'text-darkblue' : 'text-darkblue/40'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => setIsUpdateModalOpened(true)}
                  >
                    {t('settings.form.update.label')}
                  </button>
                )}
              </MenuItem>
            )}
          </MenuItems>
        </Transition>
      </Menu>
      {isUpdateModalOpened && (
        <UpdatePost post={post} onCloseModal={resetUpdatePost} onUpdateSuccess={resetUpdatePost} />
      )}
      {isRemovePostOpened && (
        <Confirmation
          onConfirm={removePost}
          onCloseModal={resetRemovablePost}
          confirmationText={t('settings.form.delete_post_confirmation.label')}
        />
      )}
      {isRemovePostAndBanUserOpened && (
        <ConfirmationRemovePostBanUser
          onConfirm={removePostAndBanUser}
          onCloseModal={resetRemovePostAndBanUser}
          confirmationText={t('settings.form.delete_post_and_ban_user_confirmation.label')}
        />
      )}
    </>
  )
}

export default PostModalEdit
