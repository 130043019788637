import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import CommentIcon from '../../../../shared/icons/comment-icon'
import { handleWithoutPropagating } from '../../../../shared/utils/handle-without-propagating'
import { LocaleEnum } from 'shared/enums/locale-enum'
import LikeButton from 'modules/community/components/like/like-button'
import PostModalEdit from 'modules/community/components/post-modal-edit'
import { PostStatusEnum } from 'modules/community/enums/post-status-enum'
import { useIsPostEditable } from 'modules/community/hooks/use-is-post-editable'
import { PostInterface } from 'modules/community/types/post-interface'
import { timeSince } from 'modules/post/utils/time-utils'
import { TextWithMentions } from '../editor/utils/deserealizeMentionToHTML'
import { PostAuthorInfo } from './author-info'
import PostModal from './post-modal'

type PostAnnounceProps = {
  post: PostInterface
  commentsCount?: number
  isClickable?: boolean
  deletePost: () => void
  deletePostAndBanUser: () => void
  handleLikeClick?: () => void
  dashboardLocale?: LocaleEnum
}

function PostAnnounce({
  post,
  commentsCount = 0,
  isClickable = true,
  deletePost,
  handleLikeClick,
  dashboardLocale,
  deletePostAndBanUser,
}: PostAnnounceProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { communityPath, topicPath: topicPathParam } = router.query
  const communityPathWithSlug = `/community/${communityPath}`
  const topicPath = `/community/${communityPath}/${post.topic.path}`
  const postDetailsPath = `${topicPath}/${post.path}`
  const isPostEditable = useIsPostEditable(post)

  const [opened, setOpened] = useState(false)

  const handleModalOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    const isLink = target.tagName.toLowerCase() === 'a'

    if (!isLink) {
      if (window) {
        window.history.replaceState(null, '', postDetailsPath)
      }
      setOpened(true)
    }
  }

  const handleModalClose = () => {
    if (window) {
      window.history.replaceState(null, '', topicPathParam ? topicPath : communityPathWithSlug)
    }
    setOpened(false)
  }

  return (
    <>
      <div
        onClick={handleModalOpen}
        className="mb-8 w-full cursor-pointer rounded-lg border border-[#eceef1] bg-white px-7 py-3 pb-1 shadow-[0px_4px_20px_0px_#F2F4F5] transition-shadow hover:shadow-[0px_4px_25px_0px_rgb(217,221,223)] lg:px-10 lg:py-4 lg:pb-2"
      >
        <div className="mb-2.5 flex justify-between md:mb-3.5">
          <div className="flex flex-wrap items-center gap-2.5">
            <PostAuthorInfo author={post.author} />
            <div className="bluegray inline lg:block">
              <span>{timeSince(new Date(post.createdAt), dashboardLocale)}</span>{' '}
              {t('community.pages_on')}{' '}
              <Link className="font-semibold text-darkblue" href={topicPath} shallow>
                #{post.topic.name}
              </Link>
            </div>
          </div>
          {isPostEditable && (
            <PostModalEdit
              post={post}
              deletePost={deletePost}
              deletePostAndBanUser={deletePostAndBanUser}
            />
          )}
        </div>
        <h2 className="community-post-title pb-2 md:pb-3">{post.title}</h2>
        <TextWithMentions
          className={twMerge(
            'preview-image-wrapper line-clamp-3 max-h-16 cursor-pointer overflow-hidden text-ellipsis break-words text-sm text-darkblue/90 md:line-clamp-2 lg:text-base',
          )}
          text={post.content}
          mentions={post.mentions}
        />
        <button className="pt-2.5 leading-none text-blue hover:underline md:pt-3.5">
          {t('post_announce.post_show_more')}
        </button>
        {post.status === PostStatusEnum.Approved && (
          <div className="mt-1 flex items-center gap-3">
            {handleLikeClick && (
              <div onClick={handleWithoutPropagating()}>
                <LikeButton
                  isLiked={post.isLiked}
                  likesCount={post.likesCount}
                  handleLikeClick={handleLikeClick}
                  postId={post.id}
                />
              </div>
            )}
            <div className="group flex items-center gap-2 rounded-md px-1.5 py-2 transition-colors hover:bg-blue/20">
              <CommentIcon className="h-6 w-6 transition-colors group-hover:fill-blue" />
              {commentsCount > 0 ? commentsCount : ''}
            </div>
          </div>
        )}
      </div>
      <PostModal
        opened={opened}
        onClose={handleModalClose}
        post={post}
        handleLikeClick={handleLikeClick}
        dashboardLocale={dashboardLocale}
        topicPath={topicPath}
        commentsCount={commentsCount}
      />
    </>
  )
}

export function PostAnnounceSkeleton() {
  return (
    <div className="mb-10 animate-pulse overflow-hidden rounded-lg bg-white">
      <div className="w-full px-5 py-5 lg:px-16 lg:py-10">
        <div className="mb-4 flex items-center gap-2">
          <div className="h-6 w-6 rounded-full bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
        </div>
        <div className="mb-2 h-7 rounded-sm bg-gray" />
        <div className="mb-5 h-7 w-3/4 rounded-sm bg-gray" />
        {Array.from(Array(6).keys()).map(index => (
          <div className="mb-3 h-3 rounded-sm bg-gray" key={index} />
        ))}
      </div>
      <hr className="mx-5 px-5 text-gray lg:mx-0" />
      <div className="flex w-full justify-between px-5 py-5 lg:px-16 lg:py-10">
        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
        </div>
        <div className="">
          <div className="h-6 w-20 rounded-sm bg-gray" />
        </div>
      </div>
    </div>
  )
}

export default PostAnnounce
