import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Modal from 'shared/components/modal'
import PrimaryButton from 'shared/components/primary-button'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'

type ConfirmationRemovePostBanUserProps = {
  onCloseModal: () => void
  onConfirm: () => void
  title?: string
  confirmationText?: string
}

export const ConfirmationRemovePostBanUser = ({
  onCloseModal,
  onConfirm,
  title = 'confirmation_modal.default_title',
  confirmationText = '',
}: ConfirmationRemovePostBanUserProps) => {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(true)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  function closeModal() {
    setOpened(false)
    onCloseModal()
  }

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await onConfirm()
      setOpened(false)
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      }
      if (e instanceof UnprocessableContentError) {
        setError(t('community.error.error_user_already_banned'))
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal opened={opened} onClose={closeModal} title={t(title)} small>
        <div className="mb-6 flex justify-center">
          <p>{confirmationText}</p>
        </div>
        <div className="mb-6 flex justify-center gap-4">
          <PrimaryButton className="ml-5 p-1 px-7" onClick={handleConfirm} isLoading={isLoading}>
            {t('confirmation_modal.confirm').toUpperCase()}
          </PrimaryButton>
          <PrimaryButton className="px-7" onClick={onCloseModal}>
            {t('confirmation_modal.cancel').toUpperCase()}
          </PrimaryButton>
        </div>
        {error && <p className="mt-2 text-center text-sm text-red">{error}</p>}
      </Modal>
    </>
  )
}
