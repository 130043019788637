import { useTranslation } from 'next-i18next'
import { getReadableMemberWorkspaceRole } from 'modules/community/enums/member-role-enum'
import { PostAuthorInterface } from 'modules/community/types/post-interface'
import EllipseIcon from 'shared/icons/ellipse-icon'

interface AuthorInfoProps {
  author?: PostAuthorInterface
}

export const PostAuthorInfo = ({ author }: AuthorInfoProps) => {
  const { t } = useTranslation()

  return (
    <p className="inline-flex items-center gap-2.5 text-bluegray">
      <img
        src={author?.avatarUrl || "/default_post_author_avatar.png"}
        alt={author?.displayName}
        className="h-10 w-10 rounded-full"
        width={40}
        height={40}
      />
      <span className="text-darkblue max-w-[100px] md:max-w-sm whitespace-nowrap text-ellipsis overflow-hidden">
        {author?.displayName}
      </span>
      {author?.workspaceRole && (
        <span
          className="text-[#0279e8]/[1] bg-[#00a0ff]/[0.07] border rounded-2xl px-[5px] border-transparent text-sm leading-5 font-medium">
            {t(getReadableMemberWorkspaceRole(author.workspaceRole))}
          </span>
      )}
      <EllipseIcon />
    </p>
  )
}
